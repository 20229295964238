
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'
  import { fixPrice } from '@/utils/general'

@Component({})
  export default class TwelveMonthUtility extends BaseChart {
  @Prop() sales: Record<string, any>
  @Prop() purchases: Record<string, any>
  @Prop() credits: Record<string, any>
  @Prop() dates: Record<string, any>
  fixPrice = fixPrice
  sums = {
    sales: [],
    credits: [],
  }

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  getData () {
    const sales = this.sales?.sales_sale_order_item_aggregate?.nodes
    const purchases = this.purchases?.sales_stock_view_aggregate?.nodes
    const credits = this.credits?.loans_offer_aggregate?.nodes

    if (!sales || !credits || !purchases) return

    this.sums = {
      sales: this.months.dates
        .map(date => {
          const filteredSales = sales.filter(sale => date.isSame(dayjs(this.convertUtc(sale.saleOrder.deal.stock.soldDate)), 'month'))
          const filteredPurchases = purchases.filter(purchase => date.isSame(dayjs(this.convertUtc(purchase.stock.soldDate)), 'month'))

          let salesSum = 0
          let purchasesSum = 0
          filteredSales.forEach(sale => {
            salesSum += sale.amount
          })
          filteredPurchases.forEach(purchase => {
            purchasesSum += purchase.totalCost
          })
          return (salesSum - purchasesSum)
        }),
      credits: this.months.dates
        .map(date =>
          credits.filter(credit => date.isSame(dayjs(this.convertUtc(credit.evaluation.signedDate)), 'month')))
        .map(credits => {
          let sum = 0
          credits.forEach(credit => sum += credit.fee)
          return sum
        }),
    }

    this.series = [
      {
        name: 'Directa',
        color: '#F9D50B',
        data: this.sums.sales,
      },
      {
        name: 'Créditos',
        color: '#7966C0',
        data: this.sums.credits,
      },
    ]

    this.chartOptions = {
      chart: {
        stacked: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: this.months.names,
      },
      yaxis: {
        labels: {
          formatter: val => fixPrice(val),
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
    }
  }

  sumArray (arr: number[]) {
    let sum = 0
    arr.forEach(item => sum += item)
    return sum
  }

  // Getters
  get months () {
    const start = dayjs(this.dates._gte)
    const end = dayjs(this.dates._lte).startOf('month')

    const dates = []
    for (let i = start; i.isBefore(end); i = i.add(1, 'month')) {
      dates.push(i)
    }

    return {
      dates,
      names: dates.map(date => {
        return {
          '01': 'Ene',
          '02': 'Feb',
          '03': 'Mar',
          '04': 'Abr',
          '05': 'May',
          '06': 'Jun',
          '07': 'Jul',
          '08': 'Ago',
          '09': 'Sep',
          10: 'Oct',
          11: 'Nov',
          12: 'Dic',
        }[date.format('MM')]
      }),
    }
  }

  get watchData () {
    return {
      sales: this.sales,
      credits: this.credits,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
