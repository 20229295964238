
  import Component from 'vue-class-component'
  import { BaseChart } from './baseChart'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'

@Component({})
  export default class DailyStock extends BaseChart {
  @Prop() stock
  @Prop() prevMonthStock
  @Prop() dates
  statuses = []

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      if (!this.stock?.sales_stock_daily) {
        return {
          start: this.dates.start,
          end: currentDate.format('YYYY-MM-DD'),
        }
      } else {
        const stock = this.stock.sales_stock_daily
        let dates = stock.map(item => dayjs(item.report_date).format('YYYY-MM-DD'))
        dates = dates.sort()
        return {
          start: this.dates.start,
          end: dates[dates.length - 1],
        }
      }
    } else return this.dates
  }

  getMeanByStatus (status) {
    let filteredByStatus = this.stock.sales_stock_daily.filter(item => item.process_status?.status?.name === status)
    if (status === 'reserved') {
      const reservationProcess = this.stock.sales_stock_daily.filter(item => item.process_status?.status?.name === 'reservation_process')
      filteredByStatus = [...filteredByStatus, ...reservationProcess]
    }
    const limitDates = this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.end))
    let sum = 0
    limitDates.forEach(date => {
      sum += filteredByStatus.filter(item => dayjs(item.report_date).isSame(dayjs(date), 'day')).length
    })
    sum /= limitDates.length
    return Math.round(sum)
  }

  getMeanStock () {
    if (!this.stock?.sales_stock_daily) {
      return
    }
    const limitDates = this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.end))
    let sum = 0
    limitDates.forEach(date => {
      sum += this.stock.sales_stock_daily.filter(item => dayjs(item.report_date).isSame(dayjs(date), 'day')).length
    })
    sum /= limitDates.length
    return Math.round(sum)
  }

  get categories () {
    return this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)
  }

  get limitRange () {
    return this.generateDayRange(
      dayjs(this.limitDates.start), dayjs(this.limitDates.end)
    )
  }

  get currentMonthRange () {
    return this.generateDayRange(
      dayjs(this.limitDates.start), dayjs(this.limitDates.start).endOf('month')
    )
  }

  fillWithNull (array, startLenght) {
    for (let i = startLenght; i < this.categories.length; i++) {
      array.push(null)
    }
  }

  getData () {
    const stock = this.stock?.sales_stock_daily
    const prevMonthStock = this.prevMonthStock?.sales_stock_daily
    if (!stock || !prevMonthStock) return

    this.statuses = [];
    ([...stock, ...prevMonthStock]).forEach(item => {
      if (!this.statuses.map(status => status.name).includes(item.process_status?.status?.name)) {
        this.statuses.push(item.process_status?.status)
      }
    })

    const currentMonthSeries = this.statuses.map(status => {
      let filteredByStatus = stock.filter(item => {
        return item.process_status?.status?.name === status.name
      })

      if (status.name === 'reserved') {
        const reservationProcess = stock.filter(item => {
          return item.process_status?.status?.name === 'reservation_process'
        })
        filteredByStatus = [...filteredByStatus, ...reservationProcess]
        this.statuses = this.statuses.filter(status => status.name !== 'reservation_process')
      }
      if (status.name === 'reservation_process') {
        const reserves = stock.filter(item => {
          return item.process_status?.status?.name === 'reserved'
        })
        filteredByStatus = [...filteredByStatus, ...reserves]
        this.statuses = this.statuses.filter(status => status.name !== 'reserved')
        status.description = 'Reservado'
      }
      // eslint-disable-next-line no-unused-vars
      const data = this.limitRange.map((_category, categoryIndex) => {
        // eslint-disable-next-line no-unused-vars
        const filteredByDate = filteredByStatus.filter((_item, itemIndex) => {
          return dayjs(filteredByStatus[itemIndex].report_date).isSame(dayjs(this.limitRange[categoryIndex]), 'day')
        })
        return filteredByDate.length
      })

      this.fillWithNull(data, this.limitRange.length)

      return {
        name: status.description,
        data,
        color: status.color.split(' ')[0],
        type: 'line',
      }
    })

    const prevMonthSeries = this.statuses.map(status => {
      let filteredByStatus = prevMonthStock.filter(item => {
        return item.process_status?.status?.name === status.name
      })

      if (status.name === 'reserved') {
        const reservationProcess = stock.filter(item => {
          return item.process_status?.status?.name === 'reservation_process'
        })
        filteredByStatus = [...filteredByStatus, ...reservationProcess]
        this.statuses = this.statuses.filter(status => status.name !== 'reservation_process')
      }
      if (status.name === 'reservation_process') {
        const reserves = stock.filter(item => {
          return item.process_status?.status?.name === 'reserved'
        })
        filteredByStatus = [...filteredByStatus, ...reserves]
        this.statuses = this.statuses.filter(status => status.name !== 'reserved')
        status.description = 'Reservado'
      }
      const limitRange = this.generateDayRange(
        dayjs(dayjs(this.limitDates.start).subtract(1, 'month')), dayjs(dayjs(this.limitDates.start).subtract(1, 'month')).endOf('month')
      ).slice(0, this.currentMonthRange.length - 1)
      const data = limitRange.map(category => {
        const filteredByDate = filteredByStatus.filter(item => {
          return dayjs(item.report_date).isSame(dayjs(category), 'day')
        })
        return filteredByDate.length
      })

      this.fillWithNull(data, limitRange.length)

      return {
        name: status.description + ' (mes anterior)',
        data,
        color: status.color.split(' ')[0] + '4C',
        type: 'line',
      }
    })

    this.series = [...currentMonthSeries, ...prevMonthSeries]

    const totals = this.limitRange.map(date => {
      return stock.filter(item => dayjs(item.report_date).isSame(dayjs(date), 'day')).length
    })

    this.fillWithNull(totals, this.limitRange.length)

    this.series.push({
      name: 'Total',
      data: totals,
      color: '#DCFCE7',
      type: 'area',
      stroke: {
        curve: 'smooth',
      },
    })

    for (let i = 0; i < this.series.length; i++) {
      this.series[i].data = this.series[i].data.slice(0, this.categories.length)
    }

    this.chartOptions = {
      labels: this.categories,
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0, 0, 0, 0, 3, 3, 3, 3],
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories: this.categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD')
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            return Math.floor(val)
          },
        },
      },
    }
  }

  @Watch('stock', { immediate: false, deep: true })
  @Watch('prevMonthStock', { immediate: false, deep: true })
  @Watch('dates', { immediate: false, deep: true })
  update () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
