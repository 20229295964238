
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import { DetailByExecutive } from '../detailByExecutive'
  import ExecutiveDetail from '../Detail/ExecutiveDetail.vue'

@Component({
  components: {
    ExecutiveDetail,
  },
})
  export default class ExpiredInspectionsByExecutive extends DetailByExecutive {
  @Prop() inspections: Record<string, any>
  @Prop() inspectionsSettings: Record<string, any>

  amount = 0
  target = 10
  cars = []
  executives = []

  // Methods
  getData () {
    const inspections = this.inspections?.getInspectionStatusReportByDate?.filter(inspection => inspection?.closingReason?.type?.name === 'expired')
    const allInspections = this.inspections?.getInspectionStatusReportByDate
    const inspectionsSettings = this.inspectionsSettings?.settingsProcess?.[0]
    if (!inspections || !inspectionsSettings || !this.staffExecutives) return
    this.amount = inspections.length
    this.target = allInspections.length

    let executives = []
    inspections.forEach(inspection => {
      if (!executives.map(executive => executive.id).includes(inspection?.executiveId)) {
        executives.push({
          name: inspection.executiveId ? inspection.executive : 'SIN EJECUTIVO',
          id: inspection.executiveId,
          target: allInspections.filter(filterInspection => filterInspection?.executiveId === inspection?.executiveId).length,
        })
      }
    })
    this.staffExecutives.forEach(executive => {
      if (!executives.map(executive => executive.id).includes(executive.id_executive)) {
        executives.push({
          name: `${executive.first_name} ${executive.surname}`,
          id: executive.id_executive,
          target: allInspections.filter(inspection => inspection?.appraisal?.deal?.lead?.executive?.id === executive.id_executive).length,
        })
      }
    })

    executives = executives.sort((prev, next) => {
      if (prev.name > next.name) {
        return 1
      } else if (prev.name < next.name) {
        return -1
      }
      return 0
    })
    executives = executives.sort((prev, next) => {
      const staffIDs = this.staffExecutives.map(staff => staff.id_executive)
      if (staffIDs.includes(prev.id)) {
        if (staffIDs.includes(next.id)) {
          return 1
        } else {
          return -1
        }
      } else {
        return 1
      }
    })

    this.executives = []
    executives.forEach(executive => {
      const filtered = inspections.filter(inspection => {
        return inspection?.executiveId === executive.id
      })

      this.executives.push({
        executive,
        amount: filtered.length,
      })
    })
    this.executives = this.executives.filter(executive => executive)
  }

  get inspectionsCount () {
    return this.inspections?.getInspectionStatusReportByDate?.length || 0
  }

  get watchData () {
    return {
      inspections: this.inspections,
      settings: this.inspectionsSettings,
      executives: this.executivesForReports,
    }
  }

  @Watch('watchData', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
