
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import { DetailByExecutive } from '../detailByExecutive'
  import ExecutiveDetail from '../Detail/ExecutiveDetail.vue'

@Component({
  components: {
    ExecutiveDetail,
  },
})
  export default class ReservesByExecutive extends DetailByExecutive {
  @Prop() reserves: Record<string, any>
  @Prop() reservesSettings: Record<string, any>

  amount = 0
  target = 10
  cars = []
  executives = []

  // Methods
  getData () {
    const reserves = this.reserves?.records
    const reservesSettings = this.reservesSettings?.settingsProcess?.[0] || { config: { projectedUtility: { quantity: 50 } } }
    if (!reserves || !reservesSettings || !this.staffExecutives) return
    this.amount = reserves.length
    this.target = reservesSettings?.config?.projectedUtility?.quantity

    let executives = []
    reserves.forEach(reserve => {
      const reserveExecutive = reserve.saleOrder.deal.lead.executive
      const idExecutive = reserveExecutive.id
      const { firstName, surname } = reserveExecutive.person
      if (!executives.map(executive => executive.id).includes(idExecutive)) {
        executives.push({
          name: idExecutive ? `${firstName} ${surname}` : 'SIN EJECUTIVO',
          id: idExecutive,
        })
      }
    })

    this.staffExecutives.forEach(executive => {
      if (!executives.map(executive => executive.id).includes(executive.id_executive)) {
        executives.push({
          name: `${executive.first_name} ${executive.surname}`,
          id: executive.id_executive,
        })
      }
    })

    executives = executives.sort((prev, next) => {
      if (prev.name > next.name) {
        return 1
      } else if (prev.name < next.name) {
        return -1
      }
      return 0
    })
    executives = executives.sort((prev, next) => {
      const staffIDs = this.staffExecutives.map(staff => staff.id_executive)
      if (staffIDs.includes(prev.id)) {
        if (staffIDs.includes(next.id)) {
          return 1
        } else {
          return -1
        }
      } else {
        return 1
      }
    })

    this.executives = []
    executives.forEach(executive => {
      const filtered = reserves.filter(reserve => {
        return reserve.saleOrder.deal.lead.executive.id === executive.id
      })

      this.executives.push({
        executive,
        amount: filtered.length,
      })
    })
    this.executives = this.executives.filter(executive => executive)
  }

  get watchData () {
    return {
      reserves: this.reserves,
      settings: this.reservesSettings,
      executives: this.executivesForReports,
    }
  }

  @Watch('watchData', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
