
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodCredits extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() credits: Record<string, any>
  @Prop() target: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives

  async getData () {
    if (!this.credits || !this.target) return
    const credits = await this.credits
    const targetCredits = await this.target

    const creditsNumber = credits.loans_evaluation_view_aggregate?.aggregate?.count || 0

    let monthlyTarget = Math.round((targetCredits?.settings_process?.[0]?.config?.projectedUtility?.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))
    if (!monthlyTarget) monthlyTarget = 5

    const percentage = (creditsNumber / monthlyTarget) * 100

    this.brief = `${creditsNumber} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '15px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
              formatter: () => 'Créditos aprobados',
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -1,
            },
          },
        },
      },
      labels: ['Créditos'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  // Watchers
  @Watch('credits', { immediate: true, deep: true })
  @Watch('target', { immediate: true, deep: true })
  @Watch('target', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
