import { render, staticRenderFns } from "./ReservesByExecutive.vue?vue&type=template&id=23fede4b&scoped=true&"
import script from "./ReservesByExecutive.vue?vue&type=script&lang=ts&"
export * from "./ReservesByExecutive.vue?vue&type=script&lang=ts&"
import style0 from "./ReservesByExecutive.vue?vue&type=style&index=0&id=23fede4b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23fede4b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VContainer,VProgressLinear})
