
  import { Component } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { mapActions } from 'vuex'
  import dayjs from 'dayjs'

@Component({
  methods: {
    ...mapActions('dashboard', ['dailyStock']),
  },
})
  export default class StockLinePrices extends BaseChart {
  dailyStock!: (variable) => Promise<Record<string, any>>;

  async mounted () {
    await this.getDailyStock()
    this.chartOptions = {
      chart: {
        height: 350,
        type: 'line',
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#FF1654', '#247BA0'],
      stroke: {
        width: [2, 2],
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Fecha',
        },
      },
      yaxis: [
        {
          title: {
            text: 'Costos',
          },
          labels: {
            formatter (value) {
              return value.toLocaleString('es-ES', { style: 'currency', currency: 'CLP' })
            },
          },
        },
        {
          opposite: true,
          title: {
            text: 'Cantidad',
          },
          labels: {
            formatter (value) {
              return value
            },
          },
        },
      ],
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
        y: {
          formatter (value, { seriesIndex }) {
            if (seriesIndex === 0) {
              return value.toLocaleString('es-ES', { style: 'currency', currency: 'CLP' })
            } else {
              return value
            }
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
      },
    }
    this.display = true
  }

  async getDailyStock () {
    const dailyStock = await this.dailyStock({
      model: {
        process: {
          table_name: {
            _eq: 'stock',
          },
        },
      },
      aggregate: { stock_created: { _gte: '2024/05/01', _lte: '2024/05/31' } },
    })
    this.series = this.processAggregates(dailyStock)
  }

  processAggregates = data => {
    const groupedByDay = data.aggregates.reduce((acc, item) => {
      const statusDescription = item.status.description
      item.stocks.nodes.forEach(stock => {
        const date = dayjs(stock.created).format('YYYY-MM-DD')
        if (!acc[date]) {
          acc[date] = { totalCost: 0, count: 0, status: statusDescription }
        } else {
          acc[date].totalCost += stock?.cost || 0
          acc[date].count += 1
        }
      })
      return acc
    }, {})

    const dates = Object.keys(groupedByDay)
    const costSeries = dates.map(date => groupedByDay[date].totalCost)
    const countSeries = dates.map(date => groupedByDay[date].count)

    return [
      {
        name: 'Total Cost',
        type: 'line',
        data: dates.map((date, index) => ({ x: date, y: costSeries[index] })),
      },
      {
        name: 'Count',
        type: 'line',
        data: dates.map((date, index) => ({ x: date, y: countSeries[index] })),
      },
    ]
  }
  }
